import React, { createContext, ReactNode, useReducer, useContext } from 'react';
export type ValidEnvironments = 'dev' | 'one' | 'two' | 'three' | 'prod';
interface AuthState {
  authenticated: boolean;
  access_token?: string;
  refresh_token?: string;
  csrf_token?: string;
  environment?: ValidEnvironments;
}

interface SetAuthenticatedAction {
  type: 'SET_AUTHENTICATED';
  payload: boolean;
}

interface SetAuthTokenAction {
  type: 'SET_ACCESS_TOKEN';
  payload: string;
}

interface SetRefreshTokenAction {
  type: 'SET_REFRESH_TOKEN';
  payload: string;
}
interface SetCsrfTokenAction {
  type: 'SET_CSRF_TOKEN';
  payload: string;
}

interface SetEnvironmentAction {
  type: 'SET_ENVIRONMENT';
  payload: ValidEnvironments;
}
interface LogoutAction {
  type: 'LOGOUT';
}

type AuthAction =
  | SetAuthenticatedAction
  | SetAuthTokenAction
  | SetRefreshTokenAction
  | LogoutAction
  | SetCsrfTokenAction
  | SetEnvironmentAction;

interface AuthContextProps extends AuthState {
  dispatch: React.Dispatch<AuthAction>;
}

const initialState: AuthState = {
  authenticated: false,
  access_token: undefined,
  refresh_token: undefined,
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'SET_ACCESS_TOKEN':
      return { ...state, access_token: action.payload, authenticated: true };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action.payload };
    case 'LOGOUT':
      return {
        authenticated: false,
        access_token: '',
        refresh_token: '',
        csrf_token: '',
        environment: 'dev',
      };
    case 'SET_REFRESH_TOKEN':
      return { ...state, refresh_token: action.payload };

    case 'SET_CSRF_TOKEN':
      return { ...state, csrf_token: action.payload };

    case 'SET_ENVIRONMENT':
      if (process.env.ENVIRONMENT !== 'prod') {
        console.log('setting environment to', action.payload);
      }
      return { ...state, environment: action.payload };

    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
