import { ProductProps } from '@/@types/common';
import { getColorClass } from '@/components/colors';
import Loading from '@/components/loading';
import { useUser } from '@/contexts/UserContext';
import { useAuth } from '@/hooks/useAuth';
import { useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const buildUrl = (
  baseUrl: string,
  params: { [key: string]: string | undefined }
) => {
  // Filtrar parámetros para asegurarnos de que no haya valores indefinidos
  const filteredParams: Record<string, string> = {};
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value !== undefined) {
      filteredParams[key] = value;
    }
  });

  const queryString = new URLSearchParams(filteredParams).toString();
  return `${baseUrl}?${queryString}`;
};

export const ProductLink: React.FC<{
  product: ProductProps;
  target: string;
  children: React.ReactNode;
  setLoading: (loading: boolean) => void;
  additionalParameters?: { [key: string]: string | undefined }; // Agregar propiedad opcional
}> = ({ product, target, children, setLoading, additionalParameters = {} }) => {
  const { refresh, auth, environment } = useAuth();
  const { userData } = useUser();

  const env = process.env.ENVIRONMENT || 'staging';
  const enpoint_param = env === 'prod' ? 'prod' : environment;

  async function executeFunctionAndRedirect() {
    if (!auth.refresh_token) {
      return;
    }
    try {
      setLoading(true);

      const refreshedToken = await refresh();
      const combinedParameters = {
        ...additionalParameters, // Combinar parámetros adicionales
        csrf_token: auth.csrf_token,
        access_token: refreshedToken.new_token.access as string,
        refresh_token: auth.refresh_token,
        lang: userData?.lang,
        environment: enpoint_param,
      };

      const finalUrl = buildUrl(product.baseUrl, combinedParameters);

      const a  = document.createElement('a');
      a.href = finalUrl;
      a.target = target;
      a.click();
      a.remove();
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  }

  return (
    <a
      href='#'
      onClick={async (e) => {
        e.preventDefault();
        if (product.enabled || userData?.is_internal) {
          await executeFunctionAndRedirect();
        } else {
          window.open(product.info_url, target, 'noreferrer');
        }
      }}
    >
      {children}
    </a>
  );
};
