import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IoHomeSharp, IoLinkSharp } from 'react-icons/io5';
import { IoIosApps } from 'react-icons/io';

import { NavItem } from '@/@types/nav-item';
import VerticalNavbar from '@/components/navbar/vertical-navbar';
import HorizontalNavbar from '@/components/navbar/horizontal-navbar';

import Products from '@/components/sections/products';
import Home from '@/components/sections/home';
import { useUser } from '@/contexts/UserContext';
import InternalLinks from '@/components/sections/internal/links';
import clsx from 'clsx';

const AdminDashboard: React.FC<{ onLogout: () => void }> = ({ onLogout }) => {
  const { t } = useTranslation();
  const { userData } = useUser();
  const [transitioning, setTransitioning] = useState(false);
  const navMargin = 12;
  const bgColor = 'gray-100';
  const marginColor = 'gray-200';

  // Define navigation items
  const navItems: NavItem[] = [
    {
      title: t('dashboard.content.home.title'),
      icon: <IoHomeSharp />,
      content: <Home />,
      public: true,
    },
    {
      title: t('dashboard.content.products.title'),
      icon: <IoIosApps />,
      content: <Products />,
      public: true,
    },
    {
      title: t('dashboard.content.internal.links.title'),
      icon: <IoLinkSharp />,
      content: <InternalLinks />,
      public: false,
    },
  ];

  // Filter navigation items based on user role
  const filteredNavItems = navItems.filter(
    (item) => item.public || userData?.is_internal
  );

  // State for selected item
  const [selectedItem, setSelectedItem] = useState<NavItem>(
    filteredNavItems[0]
  );

  // Handle transitions when the selected item changes
  useEffect(() => {
    setTransitioning(true);
    const timer = setTimeout(() => {
      setTransitioning(false);
    }, 300);

    return () => clearTimeout(timer);
  }, [selectedItem]);

  // Memoized handler to select an item
  const handleSelectItem = useCallback((item: NavItem) => {
    setSelectedItem(item);
  }, []);

  return (
    <main className='flex flex-row w-full h-screen '>
      <div className={'flex w-full'}>
        <VerticalNavbar
          navItems={filteredNavItems}
          handleSelectedItem={handleSelectItem}
          size={navMargin}
          className={`bg-${bgColor} border-r-[2px] border-${marginColor} w-12`}
        />
        <div className='flex flex-col w-full h-full'>
          <HorizontalNavbar onLogout={onLogout} />

          <section className={'flex flex-col  pl-20 pr-6 bg-gray-200 py-6'}>
            {selectedItem.content}
          </section>
        </div>
      </div>
    </main>
  );
};

export default AdminDashboard;
