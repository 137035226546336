import React, { useEffect } from 'react';
import Login from '@/components/login';
import AdminDashboard from '@/containers/admin-dashboard';
import { useAuth } from '@/hooks/useAuth';
import { ValidEnvironments } from '@/contexts/AuthContext';

const AppContent = () => {
  const { authenticated, login, logout, refresh, auth } = useAuth();

  // Efecto para manejar la autenticación automática al cargar
  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');
    const csrf_token = localStorage.getItem('csrf_token');
    const environment = localStorage.getItem(
      'environment'
    ) as ValidEnvironments;

    if (access_token && csrf_token && refresh_token) {
      login({ access_token, refresh_token, csrf_token, environment });
    }
  }, [login]);

  // Efecto para manejar la re-autenticación cuando la app vuelve a ser visible
  useEffect(() => {
    if (!authenticated) return;

    const handleVisibilityChange = async () => {
      console.log('Visibility change detected');
      if (!document.hidden && auth.refresh_token) {
        console.log('Document is visible, attempting to refresh token');
        await refresh(); // Asegúrate que `refresh` retorne un booleano indicando el éxito
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [auth.refresh_token, refresh, logout]);

  return (
    <main className='flex flex-col'>
      {!authenticated && !localStorage.getItem('access_token') ? (
        <Login onLogin={login} onLogout={logout} />
      ) : (
        <AdminDashboard onLogout={logout} />
      )}
    </main>
  );
};

export default AppContent;
