import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getProducts } from '@/data/products';
import { ProductProps } from '@/@types/common';
import { ProductLink } from '@/components/products/product';
import { FaLink } from 'react-icons/fa';
import { InternalLink } from './internal-link';

export default function InternalLinks() {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loadingKey, setLoadingKey] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Obtener productos
  const products = getProducts();

  // Filtrar productos basados en la búsqueda
  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.classification.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleLoading = (key: string) => {
    if (loadingKey === key) {
      // Si el mismo botón se presiona nuevamente, cancela la carga
      setLoadingKey(null);
      setLoading(false);
    } else {
      // De lo contrario, inicia la carga para el botón presionado
      setLoadingKey(key);
      setLoading(true);
    }
  };

  return (
    <div className='flex flex-col items-center lg:items-baseline min-h-screen'>
      <h2 className='text-2xl font-bold mb-4'>
        {t('dashboard.content.internal.links.title')}
      </h2>

      <section className='w-full mt-4'>
        <div className='mb-4'>
          <input
            type='text'
            placeholder={`${t('dashboard.content.internal.links.search')}...`}
            className='border border-gray-300 p-2 rounded w-full text-sm'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <table className='w-full border-collapse bg-white shadow-md rounded-md overflow-hidden'>
          <thead className='bg-gray-200 text-gray-700'>
            <tr>
              <th className='border-b border-gray-300 px-4 py-2 text-left'>
                {t('dashboard.content.internal.links.link')}
              </th>
              <th className='border-b border-gray-300 px-4 py-2 text-left'>
                {t('dashboard.content.internal.links.name')}
              </th>
              <th className='border-b border-gray-300 px-4 py-2 text-left'>
                {t('dashboard.content.internal.links.console')}
              </th>
              <th className='border-b border-gray-300 px-4 py-2 text-left'>
                {t('dashboard.content.internal.links.access')}
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {filteredProducts.map((product) => (
              <tr key={product.key} className='hover:bg-gray-50'>
                <td className='border-b border-gray-300 px-4 py-2'>
                  <InternalLink product={product} />
                </td>
                <td className='border-b border-gray-300 px-4 py-2'>
                  {product.name}
                </td>
                <td className='border-b border-gray-300 px-4 py-2'>
                  {product.classification}
                </td>
                <td className='border-b border-gray-300 px-4 py-2'>
                  {product.enabled ? 'Yes' : 'No'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}
