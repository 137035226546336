import { useTranslation } from 'react-i18next';
import ProjectCard from './project-card';
import { useProjects } from '@/contexts/ProjectContext';
import Loading from '../loading';
import Card from '../cards';
import { useState } from 'react';

export default function Projects() {
  const { projectData } = useProjects();
  const { t } = useTranslation();

  return (
    <div className='flex flex-col min-h-screen'>
      <h2 className='text-2xl font-bold mb-4'>
        {t('dashboard.content.home.links.projects.title')}
      </h2>

      <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4'>
        {projectData && projectData.length > 0 ? (
          <>
            {projectData?.map((project) => (
              <ProjectCard
                project={project}
                key={`${project.id_project + project.name}`}
              />
            ))}
          </>
        ) : (
          Array.from({ length: 6 }).map((_, index) => (
            <Card
              props={{
                name: '',
                description: '',
                image: '',
                products: [],
                loading: true,
              }}
              key={index}
            />
          ))
        )}
      </div>
    </div>
  );
}
