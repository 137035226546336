import { ProductProps } from '@/@types/common';
import { useProjects } from '@/contexts/ProjectContext';
import { useTranslation } from 'react-i18next';
import product_control from '@/assets/products/products_control.svg';
import product_finishes from '@/assets/products/products_finishes.svg';
import product_strength from '@/assets/products/products_strength.svg';

import product_lite from '@/assets/products/products_lite.svg';
import product_provider from '@/assets/products/products_provider.svg';
import product_tracking from '@/assets/products/products_tracking.svg';

import product_provider_info from '@/assets/products/product_provider_info.png';
import { ValidEnvironments } from '../../contexts/AuthContext';
import { useAuth } from '@/hooks/useAuth';
import { UserData } from '../../@types/common/index';
import { useUser } from '@/contexts/UserContext';
interface ConsoleURLs {
  [key: string]: string; // Las claves aquí serán 'prod', 'staging', etc.
}

interface EnvironmentConfig {
  console: ConsoleURLs;
  micro: string;
}

interface Environments {
  [key: string]: EnvironmentConfig; // Las claves aquí serán 'prod', 'staging', etc.
}

const ENVIRONMENTS: Environments = {
  prod: {
    console: {
      prod: 'https://api.sidcodx.com',
    },
    micro: 'https://newton.obralink.com',
  },
  staging: {
    console: {
      three: 'https://three.dev.obralink.com',
      dev: 'https://dev.obralink.com',
    },
    micro: 'https://gauss.obralink.com',
  },
};

export const getProducts = (): ProductProps[] => {
  const { userData } = useUser();
  const { concrete_providers, projectData } = useProjects();

  const { t } = useTranslation();
  const { environment } = useAuth() as { environment: ValidEnvironments };

  const env = process.env.ENVIRONMENT || 'staging';

  const products: ProductProps[] = [
    {
      key: 'control',
      baseUrl: `${ENVIRONMENTS[env].console[environment]}/system/dashboard/token/home/`,
      name: t('dashboard.content.products.types.control.name'),
      description: t('dashboard.content.products.types.control.description'),
      color: 'blue',
      icon_src: product_control,
      enabled: projectData?.find((project) =>
        project.products.includes('control')
      )
        ? true
        : false,
      classification: 'project-console',
      info_url: 'https://obralink.com/en/products/structural-stage',
    },

    {
      key: 'finishing',
      baseUrl: `${ENVIRONMENTS[env].console[environment]}/system/dashboard/token/home/`,
      name: t('dashboard.content.products.types.finishes.name'),
      description: t('dashboard.content.products.types.finishes.description'),
      color: 'orange',
      icon_src: product_finishes,
      enabled: projectData?.find((project) =>
        project.products.includes('finishing')
      )
        ? true
        : false,
      classification: 'project-console',
      info_url: 'https://obralink.com/es/products/finishes-control',
    },
    {
      key: 'strength_cibot',
      baseUrl: `${ENVIRONMENTS[env].console[environment]}/system/dashboard/token/home/`,
      name: t('dashboard.content.products.types.strength.name'),
      description: t('dashboard.content.products.types.strength.description'),
      color: 'green',
      icon_src: product_strength,
      enabled: projectData?.find((project) =>
        project.products.includes('strength_cibot')
      )
        ? true
        : false,
      classification: 'project-console',
      info_url: 'https://obralink.com/es/products/concrete-strength-monitoring',
    },
    {
      key: 'strength_tc_bim',
      baseUrl: `${ENVIRONMENTS[env].console[environment]}/system/dashboard/token/home/`,
      name: t('dashboard.content.products.types.strength.name'),
      description: t('dashboard.content.products.types.strength.description'),
      color: 'green',
      icon_src: product_strength,
      enabled: projectData?.find((project) =>
        project.products.includes('strength_tc_bims')
      )
        ? true
        : false,
      classification: 'project-console',
      info_url: 'https://obralink.com/es/products/concrete-strength-monitoring',
    },
    {
      key: 'strength_tc_no_bim',
      baseUrl: `${ENVIRONMENTS[env].micro}/special-projects-console/`,
      name: t('dashboard.content.products.types.strength.name'),
      description: t('dashboard.content.products.types.strength.description'),
      color: 'red',
      icon_src: product_strength,
      enabled: projectData?.find((project) =>
        project.products.includes('strength_tc_no_bim')
      )
        ? true
        : false,
      classification: 'special-project',
      info_url: 'https://obralink.com/es/products/concrete-strength-monitoring',
    },
    {
      key: 'lite',
      baseUrl: `${ENVIRONMENTS[env].console[environment]}/system/providers/concrete/login`,
      name: t('dashboard.content.products.types.lite.name'),
      description: t('dashboard.content.products.types.lite.description'),
      color: 'light-blue',
      icon_src: product_lite,
      enabled: projectData?.find((project) => project.products.includes('lite'))
        ? true
        : false,
      classification: 'project-console',
      info_url: 'https://obralink.com/es/products/lite',
    },
    {
      key: 'obralink_concrete',
      baseUrl: `${ENVIRONMENTS[env].console[environment]}/system/providers/concrete/login`,
      name: t('dashboard.content.products.types.provider.name'),
      description: t('dashboard.content.products.types.provider.description'),
      color: 'purple',
      icon_src: product_provider,
      enabled: !!concrete_providers,
      classification: 'concrete',
      info_url:
        'https://obralink.com/es/products/concrete-supplier-control-and-logistics',
      info_image_url: product_provider_info,
    },
    {
      key: 'tracking',
      baseUrl: `${ENVIRONMENTS[env].micro}/workers-tracking-console/`,
      name: t('dashboard.content.products.types.tracking.name'),
      description: t('dashboard.content.products.types.tracking.description'),
      color: 'red',
      icon_src: product_tracking,
      enabled: projectData?.find((project) =>
        project.products.includes('tracking')
      )
        ? true
        : false,
      classification: 'tracking',
      info_url: 'https://obralink.com/es/products/tracking',
    },
    {
      key: 'tracking',
      baseUrl: `${ENVIRONMENTS[env].micro}/tracking-ops-suite/`,
      name: t('dashboard.content.products.types.tracking_ops.name'),
      description: t(
        'dashboard.content.products.types.tracking_ops.description'
      ),
      color: 'red',
      icon_src: product_tracking,
      enabled: userData?.is_internal ? true : false,
      classification: 'internal',
      info_url: 'https://obralink.com/es/products/tracking',
    },
  ];

  return products;
};
