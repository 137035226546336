import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@/i18n/i18n';
import '@/styles/global.css';
import { AuthContextProvider } from '@/contexts/AuthContext';
import { UserProvider } from '@/contexts/UserContext';
import { ProjectProvider } from '@/contexts/ProjectContext';

let root: ReactDOM.Root;

/**
 * Mount function to render the app
 * @param element - The root element to render the app
 */
export function mountReact(element: HTMLElement) {
  root = ReactDOM.createRoot(element);

  root.render(
    <React.StrictMode>
      <AuthContextProvider>
        <UserProvider>
          <ProjectProvider>
            <App />
          </ProjectProvider>
        </UserProvider>
      </AuthContextProvider>
    </React.StrictMode>
  );
}

window.addEventListener(
  'LOGOUT',
  () => {
    unmountReact();
    mountReact(document.getElementById('react-app-root') as HTMLElement);
  },
  false
);

/** Unmount function to unmount the app */
export function unmountReact() {
  root.unmount();
}

if (document.getElementById('react-app-root')) {
  mountReact(document.getElementById('react-app-root') as HTMLElement);
}
