// ProjectContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import useFetch from '@/hooks/useFetch';
import { useAuth } from '@/hooks/useAuth';
import { ProjectProps } from '@/@types/common';

interface ProjectContextProps {
  projectData: ProjectProps[] | null;
  concrete_providers: boolean;
  loading: boolean;
  error: any;
}

const ProjectContext = createContext<ProjectContextProps>({
  projectData: null,
  concrete_providers: false,
  loading: true,
  error: null,
});

export const ProjectProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { authenticated } = useAuth();
  const { data, loading, error, execute } = useFetch<any>({
    url: '/projects/',
    method: 'GET',
    immediate: false,
  });

  const [projectData, setProjectData] = useState<ProjectProps[] | null>(null);
  const [concrete_providers, setConcreteProviders] = useState<boolean>(false);

  useEffect(() => {
    if (authenticated && !projectData) {
      execute();
    }
    if (!authenticated) {
      setProjectData(null);
    }
  }, [authenticated, execute, projectData]);

  useEffect(() => {
    if (data) {
      const projectData = data.data;
      const concrete_provider = data.concrete_providers;
      if (projectData) {
        setProjectData(projectData);
      }
      if (concrete_provider) {
        setConcreteProviders(concrete_provider);
      }
    } else if (error) {
      // Manejar caso de no autorizado o error en la obtención de datos
      setProjectData(null);
    }
  }, [data, error]);

  return (
    <ProjectContext.Provider
      value={{ projectData, concrete_providers, loading, error }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjects = () => useContext(ProjectContext);
