import { useCallback, useMemo } from 'react';
import { useAuthContext, ValidEnvironments } from '@/contexts/AuthContext';
import { AuthParams } from '@/@types/auth';
import { config } from '@/api/common';
import useFetch from './useFetch';
import { redirect } from 'react-router-dom';

export const useAuth = () => {
  const { authenticated, access_token, refresh_token, csrf_token, environment, dispatch } = useAuthContext();

  const req_headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${access_token}`,
    'X-CSRFToken': `${csrf_token || ''}`,
    'x-refresh-token': `${refresh_token || ''}`,
    'x-environment': `${environment}`
  };

  // Utility function to handle errors
  const handleError = (error: Error, message: string) => {
    console.error(message, error);
    // Optionally, show a notification or message to the user
    // e.g., notifyUser(message);
  };

  const login = useCallback(async (authParams: AuthParams) => {
    localStorage.setItem('access_token', authParams.access_token);
    localStorage.setItem('refresh_token', authParams.refresh_token);
    localStorage.setItem('csrf_token', authParams.csrf_token);
    localStorage.setItem('environment', authParams.environment);
    dispatch({ type: 'SET_ACCESS_TOKEN', payload: authParams.access_token });
    dispatch({ type: 'SET_REFRESH_TOKEN', payload: authParams.refresh_token });
    dispatch({ type: 'SET_CSRF_TOKEN', payload: authParams.csrf_token });
    dispatch({ type: 'SET_ENVIRONMENT', payload: authParams.environment as ValidEnvironments });
    dispatch({ type: 'SET_AUTHENTICATED', payload: true });


  }, [dispatch]);

  const logout = useCallback(async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        handleError(new Error('Missing refresh token'), 'Refresh token is missing');
        throw new Error('Missing credentials');
      }

      const response = await fetch(config.BASE_URL + '/server-logout/', {
        method: 'POST',
        body: JSON.stringify({ refresh: refreshToken }),
        headers: req_headers,
      });

      if (!response.ok) {
        handleError(new Error(`Logout failed with status ${response.status}`), 'Logout failed');
        redirect('/login');
      }

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('csrf_token');
      localStorage.removeItem('environment');
      localStorage.removeItem('session_id');

      dispatch({ type: 'LOGOUT' });
    } catch (error: any) {
      handleError(error, 'Error during logout');
      return false;
    }
  }, [dispatch, req_headers]);

  const refresh = useCallback(async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        console.error('Missing refresh token, logging out');


        redirect('/login');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('csrf_token');
        localStorage.removeItem('environment');
        localStorage.removeItem('session_id');

        dispatch({ type: 'LOGOUT' });
        return false;
      }

      const response = await fetch(config.BASE_URL + '/refresh/', {
        method: 'POST',
        body: JSON.stringify({ refresh: refreshToken }),
        headers: req_headers,
      });

      if (!response.ok) {
        if (response.status === 401) {
          console.error('Token refresh failed, logging out');


          redirect('/login');
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('csrf_token');
          localStorage.removeItem('environment');
          localStorage.removeItem('session_id');

          dispatch({ type: 'LOGOUT' });
          return false;
        }
        if (response.status !== 401) {
          handleError(new Error(`Failed to refresh token with status ${response.status}`), 'Failed to refresh token');
          throw new Error(`Failed to refresh token with status ${response.status}`);

        }
      }


      const json = await response.json();

      localStorage.setItem('access_token', json.new_token.access); // Assume the new access token is in the response
      dispatch({ type: 'SET_ACCESS_TOKEN', payload: json.new_token.access });

      return json;
    } catch (error: any) {
      handleError(error, 'Error during token refresh');
      await logout();  // Logout on any error during the refresh process
      return false;
    }
  }, [dispatch, logout, req_headers]);

  const auth = useMemo(() => ({
    access_token,
    refresh_token,
    csrf_token,
  }), [access_token, refresh_token, csrf_token]);

  return { authenticated, auth, login, logout, refresh, req_headers, environment };
};
