import { useCallback, useEffect, useState } from 'react';
import { replaceDashesWithUnderscores } from '@/utils/helpers';

import { config } from '@/api/common';
import { useAuth } from './useAuth';
interface FetchProps {
    url: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    body?: BodyInit;
    immediate?: boolean;
}
export default function useFetch<T>(props: FetchProps) {
    const { url, method, body } = props;
    const onMount = props.immediate !== undefined ? props.immediate : true;
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<T | null>(null);
    const [response, setResponse] = useState<Response | null>(null);
    const [status, setStatus] = useState<number>(404);
    const { auth, req_headers } = useAuth();
    const execute = useCallback(async (abortSignal?: AbortSignal) => {
        try {
            setLoading(true);

            const response = await fetch(config.BASE_URL + url, {
                method: method || 'GET',
                body: body,
                headers: req_headers,
                signal: abortSignal
            });
            setStatus(response.status);
            setResponse(response);


            const json = await response.json();
            if (!response.ok) {
                const message = json.message || 'An error occurred';
                setData(null);
                setError(message);
            }
            else {
                const data = json as T;
                setData(replaceDashesWithUnderscores(data));
                setError(null);
            }
            setLoading(false);
        }
        catch (error) {
            if (error instanceof DOMException && error.name === 'AbortError')
                console.warn('Fetch aborted:', 'Unmounted component');
        }
    }, [url, auth, method, body]);
    useEffect(() => {
        const abortController = new AbortController();
        onMount && execute(abortController.signal);
        return () => {
            abortController.abort();
        };
    }, [execute, onMount]);
    return { response, data, loading, error, status, execute };
}