import { useEffect, useState } from 'react';

export const SidebarButton: React.FC<any> = ({
  title,
  icon,
  selected,
  onClick,
  showDetails,
}) => {
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    if (showDetails) {
      setTimeout(() => {
        setTransitioning(true);
      }, 100); // Reducido el tiempo para una transición más rápida
    } else {
      setTransitioning(false);
    }
  }, [showDetails]);

  return (
    <button
      disabled={selected}
      title={title}
      onClick={onClick}
      className='text-gray-300'
    >
      <div className='flex items-center group justify-start'>
        <div
          className={`w-8 h-8 flex items-center justify-center rounded-lg transition-colors duration-100 ease-in-out ${
            selected
              ? 'bg-white text-gray-900'
              : 'group-hover:bg-white group-hover:text-gray-900'
          }`}
        >
          {icon}
        </div>
        <div
          className={`text-sm transition-opacity duration-500 ease-in-out text-nowrap text-primary-dark ${
            showDetails && transitioning ? 'opacity-100' : 'opacity-0'
          } ${selected ? 'font-semibold' : ''}`}
          style={{
            maxWidth: showDetails ? '100%' : '0',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            transition: 'opacity 0.5s ease-in-out, max-width 0.5s ease-in-out',
          }}
        >
          {title}
        </div>
      </div>
    </button>
  );
};
