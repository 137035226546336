import { BsBuildingsFill } from 'react-icons/bs';
import { ProductProps, ProjectProps } from '@/@types/common';
import { getProducts } from '@/data/products';

import Card from '@/components/cards';
import { ProductLink } from '@/components/products/product';
import { useState } from 'react';

export default function ProjectCard({ project }: { project: ProjectProps }) {
  const [loading, setLoading] = useState(false);
  let associatedProducts = getProducts().filter((product) =>
    project.products.includes(product.key)
  );

  // Check for the presence of specific product keys and prioritize 'strength_cibot'
  const cibotProduct = associatedProducts.find(
    (product) => product.key === 'strength_cibot'
  );
  const bimProduct = associatedProducts.find(
    (product) => product.key === 'strength_tc_bim'
  );

  if (cibotProduct && bimProduct) {
    const indexToRemove = associatedProducts.indexOf(bimProduct);
    if (indexToRemove !== -1) {
      associatedProducts.splice(indexToRemove, 1); // Remove the bimProduct from the array
    }
  }

  return (
    <ProductLink
      product={associatedProducts[0]}
      target='_blank'
      setLoading={setLoading}
      additionalParameters={{ project_id: project.id_project.toString() }}
    >
      <Card
        props={{
          name: project.name,
          description: project.name,
          image: project.thumbnail_url,
          products: associatedProducts,
          loading: loading,
        }}
      />
    </ProductLink>
  );
}
