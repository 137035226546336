import { ProductProps } from '@/@types/common';
import { ProductLink } from '@/components/products/product';
import { useState } from 'react';
import { FaLink } from 'react-icons/fa';

interface InternalLinkProps {
  product: ProductProps;
}
export const InternalLink = ({ product }: { product: ProductProps }) => {
  const [loading, setLoading] = useState(false);
  return (
    <ProductLink product={product} target='_blank' setLoading={setLoading}>
      {loading ? <FaLink className='animate-spin' /> : <FaLink />}
    </ProductLink>
  );
};
