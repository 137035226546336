import { ProductProps } from '@/@types/common';

import { getColorClass } from '@/components/colors';
import { useTranslation } from 'react-i18next';

export default function ProductCard({ product }: { product: ProductProps }) {
  const color = getColorClass(product.color);
  const { t } = useTranslation();

  return (
    <div
      className={`p-4  rounded-lg border-[1.5px] border-gray-200 group hover:bg-white bg-white shadow-md`} // Increased padding
    >
      <div className='flex flex-col justify-between h-full'>
        <div className='flex items-start justify-between gap-2 w-full group-hover:bg-white'>
          <img
            src={product.icon_src}
            alt={product.name}
            className='w-12 h-12 rounded-md'
          />
          <div className='flex flex-col justify-start flex-1 ml-2'>
            <h2 className='text-primary-dark text-start font-semibold text-base'>
              Obralink {product.name}
            </h2>
            <p className='text-xs text-gray-500 mt-1 leading-relaxed'>
              {product.description}
            </p>
          </div>
        </div>
        <div className='w-full flex justify-end mt-2'>
          <a href={product.info_url} target='_blank' rel='noreferrer'>
            <p className='text-xs text-gray-600 px-3 py-1 hover:bg-gray-100 border-2 rounded-lg border-gray-400'>
              {t('links.more_info')}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}
