import { useTranslation } from 'react-i18next';
import { getProducts } from '@/data/products';
import ProductCard from '../../products/product-card';
import { ProductProps } from '@/@types/common';
import { useState, useEffect } from 'react';

export default function Products() {
  const { t } = useTranslation();

  // Filtrar productos habilitados y no habilitados una sola vez
  const products = getProducts();
  const filteredProducts: ProductProps[] = [];

  const seenNames = new Set<string>();

  products.forEach((product) => {
    if (
      !seenNames.has(product.name) &&
      !(product.classification === 'internal')
    ) {
      filteredProducts.push(product);
      seenNames.add(product.name);
    }
  });

  const renderSection = (title: string, products: ProductProps[]) => (
    <section className='w-full mt-4'>
      <h3 className='text-base font-semibold mt-4 mb-2'>{title}</h3>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
        {products.map((product) => (
          <ProductCard product={product} key={product.name} />
        ))}
      </div>
    </section>
  );

  return (
    <div className='flex flex-col items-center lg:items-baseline min-h-screen gap-2'>
      {/* Título principal */}
      <h2 className='text-2xl font-bold mb-4'>
        {t('dashboard.content.products.title')}
      </h2>
      {renderSection(
        t('dashboard.content.products.enabled_products.title'),
        filteredProducts.filter((product) => product.enabled)
      )}
      {renderSection(
        t('dashboard.content.products.disabled_products.title'),
        filteredProducts.filter((product) => !product.enabled)
      )}
    </div>
  );
}
