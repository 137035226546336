import { BsBuildingsFill } from 'react-icons/bs';
import { ProductProps, ProjectProps } from '@/@types/common';
import { getProducts } from '@/data/products';
import { ProductLink } from '../products/product';

export interface CardProps {
  name: string;
  description: string;
  image: string;
  products: ProductProps[];
  loading?: boolean;
}

export default function Card({ props }: { props: CardProps }) {
  return (
    <div className='flex bg-white p-4 rounded-lg shadow-md cursor-pointer transition-all duration-300 w-80 '>
      <div className='flex gap-4'>
        {props.loading ? (
          <div className='w-32 h-32 rounded-lg bg-gray-200 animate-pulse' />
        ) : (
          <img
            src={props.image}
            alt={props.name}
            className='w-32 h-32 rounded-lg object-cover'
          />
        )}

        <div className='flex flex-col gap-4 justify-between'>
          <p className='text-primary-dark text-start font-semibold text-sm lg:text-base'>
            {props.name}
          </p>

          <div className='flex gap-2'>
            {props.products.map((product) => (
              <div className='flex' key={product.key}>
                {product.icon_src && (
                  <div className='relative flex flex-col items-center group'>
                    <img
                      src={product.icon_src}
                      alt={product.name}
                      className='h-8 w-8 group-hover:opacity-100 opacity-75 transition-opacity duration-300'
                    />
                    <div className='absolute hidden group-hover:block bg-white text-black p-1 rounded shadow-md mt-8'>
                      <h2 className='text-xs lg:text-sm'>{product.name}</h2>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
