import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '@/assets/images/iso.svg';
import iso_logo from '@/assets/images/isologo.svg';
import { GiHamburgerMenu } from 'react-icons/gi';
import { SidebarButton } from './sidebar-button/index';
import { NavItem } from '@/@types/nav-item';
import product_tracking from '@/assets/images/favicon/apple-touch-icon.png';

interface VerticalNavbarProps {
  navItems: NavItem[];
  handleSelectedItem: (item: NavItem) => void;
  className?: string;
  size?: number;
}

const VerticalNavbar: React.FC<VerticalNavbarProps> = ({
  navItems,
  handleSelectedItem,
  className,
  size,
}) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [transitioning, setTransitioning] = useState<boolean>(false);

  useEffect(() => {
    const timeout = 400;
    setTimeout(() => {
      setTransitioning(true);
    }, timeout);
    setTransitioning(false);
  }, [showDetails]);

  const handleToggleDetails = useCallback(() => {
    setShowDetails((prev) => !prev);
  }, []);

  const getFilteredIndex = useCallback(
    (item: NavItem) => {
      const index = navItems.indexOf(item);
      return index !== -1 ? index : 0;
    },
    [navItems]
  );

  const handleSelect = useCallback(
    (item: NavItem) => {
      const index = getFilteredIndex(item);
      if (index !== null) {
        setSelectedIndex(index);
        handleSelectedItem(item);
      }
    },
    [getFilteredIndex, handleSelectedItem]
  );

  const filteredItems = useMemo(
    () => navItems.filter((item) => item.public),
    [navItems]
  );

  return (
    <section
      className={`h-full z-50 fixed flex flex-col justify-between text-white border-r-2 border-gray-300 bg-white transition-all duration-500 ease-in-out ${
        showDetails ? 'w-64' : 'w-12'
      } ${className || ''}`}
    >
      <aside
        className={`flex flex-col justify-start ${
          showDetails ? 'items-start pl-4' : 'items-center'
        } transition-all duration-500`}
      >
        <img
          src={showDetails ? iso_logo : logo}
          alt='Logo'
          className='h-10 p-2 mt-1 transition-all duration-500'
        />

        <div className='relative flex items-center justify-center w-full mt-0.5'>
          <button
            className='absolute flex items-center justify-center w-7 h-7 rounded-full text-black bg-white -right-4 shadow-md hover:bg-gray-100 transition-all duration-300'
            onClick={handleToggleDetails}
          >
            <GiHamburgerMenu size={14} />
          </button>
          <hr className='w-full border-t-2 border-gray-300 transition-all duration-500' />
        </div>
        <div className='flex flex-col justify-start'>
          <div className='flex flex-col gap-2 mt-4'>
            {filteredItems.map((item: NavItem, index: number) => (
              <SidebarButton
                key={index}
                title={item.title}
                icon={item.icon}
                selected={selectedIndex === getFilteredIndex(item)}
                onClick={() => handleSelect(item)}
                showDetails={showDetails}
              />
            ))}
          </div>

          {navItems.some((item) => !item.public) && (
            <div className='w-full justify-center my-2'>
              <div
                className={`h-[1px] bg-gray-200 transition-opacity ${
                  showDetails ? 'w-2/3' : 'w-full'
                } ${transitioning ? 'opacity-100' : 'opacity-0'}`}
              />
            </div>
          )}
        </div>

        <div className='flex flex-col gap-2 mt-4'>
          {navItems
            .filter((item) => !item.public)
            .map((item, index) => (
              <SidebarButton
                key={index}
                title={item.title}
                icon={item.icon}
                selected={selectedIndex === getFilteredIndex(item)}
                onClick={() => handleSelect(item)}
                showDetails={showDetails}
              />
            ))}
        </div>
      </aside>
    </section>
  );
};

export default VerticalNavbar;
