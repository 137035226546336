import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSignOutAlt, FaUser } from 'react-icons/fa';
import { useUser } from '@/contexts/UserContext';
import EP_ENVIRONMENTS from '@/utils/environments';
import { useAuth } from '@/hooks/useAuth';

const PersonButton: React.FC<{ onLogout: () => void }> = ({ onLogout }) => {
  const { userData } = useUser();
  const [showLogout, setShowLogout] = useState(false);
  const { t } = useTranslation();
  const { environment } = useAuth();

  const handleMouseEnter = () => {
    setShowLogout(true);
  };

  return (
    <div
      className='relative  top-0'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setShowLogout(false)}
    >
      <div className='flex rounded-lg  items-center  bg-primary text-primary-dark cursor-pointer transition duration-300 px-2 hover:bg-gray-200 mr-3'>
        <FaUser />
        <span className='ml-2 text-base text-primary-dark'>
          {userData?.first_name || ''}
        </span>
      </div>
      {showLogout && (
        <div className='absolute top-full right-0 pt-2 w-72 person-button-container shadow-sm'>
          <div className='bg-white rounded-lg text-sm text-clip overflow-hidden divide-y-[1px]'>
            <div className='flex px-4 py-2 text-gray-700 hover:bg-gray-200 w-full gap-4'>
              <div className='p-2 flex items-center justify-center'>
                <FaUser />
              </div>
              <div className='flex flex-col'>
                <span className='text-base font-bold'>
                  {userData?.full_name}
                </span>
                <span className='text-xs'>{userData?.email}</span>
              </div>
            </div>

            <a
              className='flex px-4 py-2 text-gray-700 hover:bg-gray-200 w-full'
              href={`${
                EP_ENVIRONMENTS[environment as string]
              }/system/sdx/users/recover/send/`}
            >
              {t('navbar.change_password')}
            </a>
            <button
              className='flex px-4 py-2 text-gray-700 hover:bg-gray-200 w-full'
              onClick={onLogout}
            >
              <div className='flex gap-2 items-center'>
                <FaSignOutAlt />
                <span>{t('navbar.logout')}</span>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

interface HorizontalNavbarProps {
  onLogout: () => void;
}

const HorizontalNavbar: React.FC<HorizontalNavbarProps> = ({ onLogout }) => {
  return (
    <nav
      className={`bg-white flex pl-12 justify-end items-center border-b-[2px] border-gray-300 text-black z-20 h-12`}
      style={{ minHeight: '3rem', height: '100%' }}
    >
      <div className='flex items-center h-full'>
        <PersonButton onLogout={onLogout} />
      </div>
    </nav>
  );
};

export default HorizontalNavbar;
