export const getColorClass = (color?: string) => {
  switch (color) {
    case "blue":
      return { bgClass: "bg-secondary-blue", textClass: "text-blue-600" };
    case "orange":
      return { bgClass: "bg-secondary-orange", textClass: "text-orange-600" };
    case "green":
      return { bgClass: "bg-secondary-green", textClass: "text-green-600" };
    case "red":
      return { bgClass: "bg-secondary-red", textClass: "text-red-600" };
    case "purple":
      return { bgClass: "bg-secondary-purple", textClass: "text-purple-600" };
    case "light-blue":
      return {
        bgClass: "bg-secondary-light-blue",
        textClass: "text-light-blue-600",
      };
    default:
      return { bgClass: "bg-gray-600", textClass: "text-gray-600" }; // Default color if none is provided
  }
};
