/**
 * Helper function to replace all dashes with underscores in an object's keys.
 * @param obj the object to replace dashes with underscores in its keys
 * @returns the object with all dashes replaced with underscores in its keys
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function replaceDashesWithUnderscores(obj: any) {
  if (obj && typeof obj === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newObj: any = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = key.replace(/-/g, '_');
        newObj[newKey] = replaceDashesWithUnderscores(obj[key]);
      }
    }
    return newObj;
  } else {
    return obj;
  }
}