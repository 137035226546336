// UserContext.tsx

import React, { createContext, useContext, useState, useEffect } from 'react';
import useFetch from '@/hooks/useFetch';
import { useAuth } from '@/hooks/useAuth';
import { UserData } from '@/@types/common';
import i18next from 'i18next';
import { redirect } from 'react-router-dom';

interface UserContextProps {
  userData: UserData | null;
  loading: boolean;
  error: any;
}

const UserContext = createContext<UserContextProps>({
  userData: null,
  loading: true,
  error: null,
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { authenticated, logout, refresh } = useAuth();
  const { status, data, loading, error, execute } = useFetch<any>({
    url: '/user/',
    method: 'GET',
    immediate: false,
  });

  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    if (authenticated && !userData) {
      execute();
    }
    if (!authenticated) {
      setUserData(null);
    }
  }, [authenticated, execute, userData]);

  useEffect(() => {
    if (data) {
      const userData = data.data;

      if (userData) {
        const userWithLang = {
          ...userData,
          lang: userData.lang.split('-')[0] || 'en',
        };
        setUserData(userWithLang);
        console.log('User data lang:', userWithLang.lang);

        // Configura el idioma en i18next
        i18next.changeLanguage(userWithLang.lang);
      }
    } else if (error) {
      refresh();
    }
  }, [data, error]);

  return (
    <UserContext.Provider value={{ userData, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
