import Card from '@/components/cards';
import { ProductLink } from '@/components/products/product';
import Projects from '@/components/projects';
import { getProducts } from '@/data/products';
import { useState } from 'react';
import { FaLink } from 'react-icons/fa';

export default function Home() {
  const products = getProducts();
  const concrete_providers_prod = products.find(
    (product) => product.classification === 'concrete'
  );
  const [linkLoading, setLinkLoading] = useState<boolean>(false);

  return (
    <div className='flex flex-col items-start min-h-screen w-full gap-10'>
      {concrete_providers_prod?.enabled && (
        <div className='flex items-start flex-col'>
          <h2 className='text-2xl font-bold mb-4'>Concrete Providers</h2>

          <div className='flex items-center'>
            <ProductLink
              product={concrete_providers_prod}
              target='_blank'
              key={concrete_providers_prod.key}
              setLoading={setLinkLoading}
            >
              <Card
                props={{
                  name: concrete_providers_prod.name,
                  description: concrete_providers_prod.classification,
                  image: concrete_providers_prod.info_image_url || '',
                  products: [concrete_providers_prod],
                  loading: linkLoading,
                }}
              />
            </ProductLink>
          </div>
        </div>
      )}

      <div className='flex items-center'>
        <Projects />
      </div>
    </div>
  );
}
